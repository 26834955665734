(function (window, $) {
   //This table is meant to be indexed by the first 3 digits of a z-value [ calculated as z = (score - mean) / standard_deviation ]
   Math.zTable =
      [0.50, 0.50, 0.50, 0.51, 0.51, 0.51, 0.52, 0.52, 0.53, 0.53,
       0.53, 0.54, 0.54, 0.55, 0.55, 0.55, 0.56, 0.56, 0.57, 0.57,
       0.57, 0.58, 0.58, 0.59, 0.59, 0.59, 0.60, 0.60, 0.61, 0.61,
       0.61, 0.62, 0.62, 0.62, 0.63, 0.63, 0.64, 0.64, 0.64, 0.65,
       0.65, 0.65, 0.66, 0.66, 0.67, 0.67, 0.67, 0.68, 0.68, 0.68,
       0.69, 0.69, 0.69, 0.70, 0.70, 0.70, 0.71, 0.71, 0.71, 0.72,
       0.72, 0.72, 0.73, 0.73, 0.73, 0.74, 0.74, 0.74, 0.75, 0.75,
       0.75, 0.76, 0.76, 0.76, 0.77, 0.77, 0.77, 0.77, 0.78, 0.78,
       0.78, 0.79, 0.79, 0.79, 0.79, 0.80, 0.80, 0.80, 0.81, 0.81,
       0.81, 0.81, 0.82, 0.82, 0.82, 0.82, 0.83, 0.83, 0.83, 0.83,
       0.84, 0.84, 0.84, 0.84, 0.85, 0.85, 0.85, 0.85, 0.85, 0.86,
       0.86, 0.86, 0.86, 0.87, 0.87, 0.87, 0.87, 0.87, 0.88, 0.88,
       0.88, 0.88, 0.88, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89, 0.90,
       0.90, 0.90, 0.90, 0.90, 0.90, 0.91, 0.91, 0.91, 0.91, 0.91,
       0.91, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.92, 0.93, 0.93,
       0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.94, 0.94, 0.94, 0.94,
       0.94, 0.94, 0.94, 0.94, 0.94, 0.95, 0.95, 0.95, 0.95, 0.95,
       0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.96, 0.96, 0.96, 0.96,
       0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.97,
       0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97,
       0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.98, 0.98, 0.98, 0.98,
       0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98,
       0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98,
       0.98, 0.98, 0.98, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
       0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
       0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
       0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
       0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
       0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
       0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
       0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99];
   Math.averageAndStandardDeviation = function( numberArray ) {
      if (!Array.isArray(numberArray)) {
         throw "Argument must be an array of numbers";
      }
      if (numberArray.length <= 0) {
         return {average: 0, standardDeviation: 0};
      }
      
      var sumOfTerms = 0.0;
      var sumOfSquares = 0.0;
      for (var j = 0; j < numberArray.length; j++) {
         sumOfTerms += numberArray[j];
         sumOfSquares += Math.pow(numberArray[j], 2);
      }
      var average = sumOfTerms / numberArray.length;
      var standardDeviation = Math.sqrt(Math.abs((sumOfSquares / numberArray.length) - Math.pow(average, 2)));
      return { average: average, standardDeviation: standardDeviation };
   };
   var doesZIndexExistInPrecalculatedTable = function(zValue) {
      return zValue >= 0 && zValue < Math.zTable.length;
   };
   Math.percentileRank = function (number, average, standardDeviation) {
      if (standardDeviation === 0) {
         return 100;
      }
      var zValue = (number - average) / standardDeviation;
      var percentileRank;
      if (zValue > 0) {
         zValue = Math.floor(zValue * 100);
         percentileRank = doesZIndexExistInPrecalculatedTable(zValue) ? Math.zTable[zValue] : 1;
      } else {
         zValue = Math.floor((0 - zValue) * 100);
         percentileRank = doesZIndexExistInPrecalculatedTable(zValue) ? 1 - Math.zTable[zValue] : 0;
      }
      return Math.floor(percentileRank * 100);
   };
}(window, jQuery));