(function (moment) {
   moment.updateLocale('en', {
      calendar: {
         lastDay: '[Yesterday at] LT',
         sameDay: '[Today at] LT',
         nextDay: '[Tomorrow at] LT',
         lastWeek: '[Last] dddd [at] LT',
         nextWeek: 'dddd [at] LT',
         sameElse: 'LLL'
      }
   });
}(moment));